import { TYPES } from "actions/index";
import { request, requestGet, requestDelete } from "network/request.js";

var tempNonLyricsResponse = {
  nodes: [],
  nodeTypes: [],
  deletedNodes: []
};
var isNonLyricsPending = false;

export function fetchAllNonLyrics(trimLanguageInfo = true, sinceTimestamp = 0) {
  const wasFetchPending = isNonLyricsPending;
  isNonLyricsPending = true;
  if (sinceTimestamp == 0) {
    tempNonLyricsResponse = {
      nodes: [],
      nodeTypes: [],
      deletedNodes: []
    };
  }
  const sinceTimestampParam = "sinceTimestamp=" + sinceTimestamp;
  const path = trimLanguageInfo
    ? "/api/graph/allNonLyrics?" + sinceTimestampParam
    : "/api/graph/allNonLyrics?trimLanguageInfo=false&" + sinceTimestampParam;
  return dispatch => {
    // ugh throw away the response if the request was already pending
    if (wasFetchPending) {
      return;
    }
    requestGet(path).then(res => {
      isNonLyricsPending = false;
      const receiveAction = _receiveAllNonLyrics(res.data.nodeTypes, res.data.nodes, res.data.deletedNodes, trimLanguageInfo);
      if (!!receiveAction) {
        dispatch(receiveAction);
      }
    }).catch(error => {
      isNonLyricsPending = false;
    });
  };
}

export function saveNode(node, nodeId = 0) {
  const create = nodeId === 0;
  return dispatch => {
    return request({
      method: create ? "post" : "put",
      url: create ? `/api/graph/nodes` : `/api/graph/nodes/${nodeId}`,
      data: node
    }).then(res => {
      dispatch(_receiveNode(res.data));
      return res.data;
    });
  };
}

export function deleteNode(nodeId) {
  return dispatch => {
    return requestDelete(`/api/graph/nodes/${nodeId}`).then(res => {
      dispatch(_receiveDeleteNode(nodeId, res.data));
    });
  };
}

export function clearFetchedNodes() {
  tempNonLyricsResponse.nodes = [];
  tempNonLyricsResponse.nodeTypes = [];
  tempNonLyricsResponse.deletedNodes = [];
  return dispatch => {
    dispatch(_receiveAllNonLyrics([], [], []));
  };
}

function _receiveAllNonLyrics(nodeTypes, nodes, deletedNodes, trimLanguageInfo) {
  const tempMap = new Map();
  const previousTimestamp = tempNonLyricsResponse.lastFetchedTimestamp || -1;

  // Add existing nodes to the temp map
  tempNonLyricsResponse.nodes.forEach(node => {
    tempMap.set(node.nodeId, node);
  });

  // Add new nodes, replacing if they have a more recent 'updated' timestamp
  nodes.forEach(node => {
    const existingNode = tempMap.get(node.nodeId);
    if (!existingNode || node.updated > existingNode.updated) {
      tempMap.set(node.nodeId, node);
    }
  });

  // Accumulate deleted nodes
  tempNonLyricsResponse.deletedNodes = [
    ...(tempNonLyricsResponse.deletedNodes || []),
    ...deletedNodes
  ];

  tempNonLyricsResponse.nodeTypes = nodeTypes;

  // Filter out deleted nodes
  const deletedNodeIds = new Set(tempNonLyricsResponse.deletedNodes.map(n => n.nodeId));
  const finalNodes = Array.from(tempMap.values()).filter(
    node => !deletedNodeIds.has(node.nodeId)
  );

  // Persist the filtered nodes
  tempNonLyricsResponse.nodes = finalNodes;

  // Calculate sinceTimestamp safely
  const sinceTimestampTemp = finalNodes.length
    ? Math.max(...finalNodes.map(n => n.updated))
    : previousTimestamp;

  const deletedNodeTimestamps = !tempNonLyricsResponse.deletedNodes ? [] : tempNonLyricsResponse.deletedNodes.map(n => n.created);
  const sinceTimestamp = Math.max(sinceTimestampTemp, ...deletedNodeTimestamps);

  // Prevent infinite loop if sinceTimestamp hasn't changed
  if (sinceTimestamp === previousTimestamp) {
    console.warn("No new updates detected. Stopping to prevent infinite loop.");
    return {
      type: TYPES.RECEIVE_ALL_NON_LYRICS,
      payload: {
        nodeTypes: tempNonLyricsResponse.nodeTypes,
        nodes: finalNodes
      }
    };
  }

  // Stop fetching if no new nodes or deletedNodes are present
  if (nodes.length === 0) {
    console.log("No new nodes or deleted nodes. Fetch complete.");
    return {
      type: TYPES.RECEIVE_ALL_NON_LYRICS,
      payload: {
        nodeTypes: tempNonLyricsResponse.nodeTypes,
        nodes: finalNodes
      }
    };
  }

  console.log(`Fetching more data... Last timestamp: ${sinceTimestamp}`);

  tempNonLyricsResponse.lastFetchedTimestamp = sinceTimestamp;

  return fetchAllNonLyrics(trimLanguageInfo, sinceTimestamp);
}

function _receiveNode(node) {
  return {
    type: TYPES.RECEIVE_NODE,
    payload: node
  };
}

function _receiveDeleteNode(nodeId, updatedNodes) {
  // Remove the node immediately from tempNonLyricsResponse.nodes
  tempNonLyricsResponse.nodes = tempNonLyricsResponse.nodes.filter(
    node => node.nodeId !== nodeId
  );

  // Also, add it to deletedNodes to ensure it's handled in future fetches
  tempNonLyricsResponse.deletedNodes.push({ nodeId });

  return {
    type: TYPES.DELETE_NODE,
    payload: {
      nodeId,
      updatedNodes
    }
  };
}
